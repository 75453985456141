<template>
    <div class="page">
      <h1>Complete the following information to register</h1>

      <form @submit.prevent="" v-on:submit="CreerCompte()">
        <div>
          <div class="newLine">
            <label>E-mail address</label>
            <input v-model="email" type="text" id="email" placeholder="email" required/>
          </div>
          <div class="newLine">
            <label>Username</label>
            <input v-model="username" type="text" id="username" placeholder="username" required/>
          </div>
          <div class="newLine">
            <label>Password</label>
            <input v-model="password" type="password" id="password" placeholder="password" required/>
          </div>
          <div class="newLine">
            <label>Confirm your password</label>
            <input type="password" id="confirm_password" placeholder="confirm password" required/>
          </div>
        </div>
        <div class="grid">
          <div class="button_line1">
            <button type="submit" class="register">Register</button>
          </div>
          <div class="button_line2">
            <label class="label_conn">Already have an account?</label>
            <button v-on:click="goToConnexion()" class="signIn">Sign in</button>
          </div>
        </div>

      </form>
    </div>
</template>
<script>
const axios = require('axios');
import router from '../router'

export default {
  name: 'CreationCompte',
  data: () => ({
    username: '',
    password: '',
    email: ''
  }),
  methods: {
    async CreerCompte () {
      if (document.getElementById("username").value.trim() == "" ) {
        alert("This username is not valid")
        return
      }
      if (!document.getElementById("email").value.toLowerCase()
      .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        alert("This e-mail address is not valid")
        return
      }
      if (document.getElementById("password").value.length < 7) {
        alert("Password must be a minimum of 7 characters")
        return
      }
      if (document.getElementById("password").value == document.getElementById("confirm_password").value) {
        this.username = document.getElementById("username").value
        this.password = document.getElementById("password").value
        this.email = document.getElementById("email").value
        var headers = {
            'Content-Type': 'application/json'
        }

        var data = {
          username: this.username,
          password: this.password,
          email: this.email
        }
        await axios.post('https://api.fkv-vss.com/api/CreateAccount', data, {headers:headers}).then(function (response) {
            
          if (response.data.message === "Your account has been registered") {
            router.push('/').catch(() => {})
          }
          alert(response.data.message)
        })
      }
      else {
        alert("Passwords don't appear to match")
      }
  },
  goToConnexion: function () {
        router.push('/').catch(() => {})
    },
}
}
</script>

<style scoped>
.grid div {
  display:inline-block;
}
.button_line1 {
  float: left
}
.button_line2 {
  float: right
}
.page {
  width: 50%;
  
  margin-left: 25%;
}
input {
  width: 100%;
}
.signIn {
  float: right;
}
.register {
  float: left;
  margin-top: 12.5%;
}
label {
  display: block;
  text-align: left;
}

.connexion {
  
  float:right;
  margin-right: 25%;
}
.label_conn {
  text-align: right;
}
.label_reg {
  text-align: left;
}
@media only screen and (max-width: 934px) {
  .page {
    width: 100%;
    margin-left: 0%;
  }
  button {
    height: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  h1 {
    font-size: 20px;
  }

}
@media only screen and (max-width: 474px) {
  .signIn {
    float: unset;
  }
  .register {
    float: unset;
  }
  .button_line1 {
    float: unset
  }
  .button_line2 {
    float: unset
  }
  .label_conn {
    text-align: center;
  }
}
</style>